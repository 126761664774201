import React from "react";
import {Helmet} from "react-helmet";
import { useSelector } from "react-redux";

const AboutUsComponent = () => {
  const { data } = useSelector((state) => state.language);
  const language = useSelector(state => state.language.language);
  const metaDescriptionTag = document.querySelector(
    'meta[name="description"]'
  );
  if (metaDescriptionTag) {
    metaDescriptionTag.setAttribute("content", "Perfume Atelier 2015 yılında, kokunun insan hayatında çok kişisel bir yolculuk olduğuna inanan bir grup genç girişimci tarafından İstanbul’da kuruldu.");
  } else {
    const newMetaTag = document.createElement("meta");
    newMetaTag.name = "description";
    newMetaTag.content = "Perfume Atelier 2015 yılında, kokunun insan hayatında çok kişisel bir yolculuk olduğuna inanan bir grup genç girişimci tarafından İstanbul’da kuruldu.";
    document.head.appendChild(newMetaTag);
  }
  return (
    <><Helmet>
    <title>{data.hakkimizda} | Perfume Atelier</title>
    <meta name="description" content="Perfume Atelier ile Kokuyu Hissedin!"/>
    <link
      rel="canonical"
      href={`https://www.perfumeatelier.com.tr/${language}/hakkimizda`}
    />
     <link rel="icon" href="/favicon.ico" />
  </Helmet>
      <div className="container-fluid main-space">
        <div className="col-12 d-flex align-items-start">
          <div>
            <h1 className="mb-3">
              <b>Perfume Atelier</b>
            </h1>
            
            <h2> {data.hakkimizda_baslik}</h2>
            <p>
            {data.hakkimizda_seo}
            </p>
            <p>
            {data.hakkimizda_aciklama}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUsComponent;
