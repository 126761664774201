import React, { useState } from "react";
import "../assets/css/headerSection.css";
import { FaRegCheckCircle } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { RiFacebookFill } from "react-icons/ri";
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa";
import Marquee from "react-fast-marquee";
import Language from "./Language";
import { useSelector } from "react-redux";

const HeaderSectionTwo = () => {
  const { data } = useSelector((state) => state.language);

  return (
    <>
      <div
        className="header-section-two"
        style={{ borderBottom: "1px solid black" }}
      >
        <div className="col-12 d-flex p-2 marque-text bg-white align-items-center">
          <div className="col-1  header-item-none">
            {" "}
            <div className="col-12 footer-social">
              <div className="d-flex gap-2">
                <a
                  target="_blank"
                  rel="noreferrer"
                  aria-label="instagram"
                  href="https://www.instagram.com/perfumeatelier/"
                >
                  <FaInstagram />
                </a>

                <a
                  target="_blank"
                  rel="noreferrer"
                  aria-label="facebook"
                  href="https://www.facebook.com/perfumeatelier/"
                >
                  <RiFacebookFill />
                </a>

                <a
                  target="_blank"
                  rel="noreferrer"
                  aria-label="twitter"
                  href="https://x.com/i/flow/login?redirect_after_login=%2FPA_turkey"
                >
                  <FaXTwitter />
                </a>

                <a
                  target="_blank"
                  rel="noreferrer"
                  aria-label="linkedin"
                  href="https://tr.linkedin.com/company/perfume-atelier?trk=ppro_cprof"
                >
                  <FaLinkedinIn />
                </a>
              </div>
            </div>
          </div>
          <div className="col-xl-10 col-lg-12 col-12">
            {" "}
            <Marquee>
              <div className=" d-flex">
              <FaRegCheckCircle /> <h6> {data.kayan_yazi_mobil}</h6>
             
                <FaRegCheckCircle /> <h6> {data.uzeri_ucretsiz_kargo}</h6>
                <FaRegCheckCircle />
                <h6> {data.ilk_alisverise_ozel_kod_header}</h6>
                <FaRegCheckCircle /> <h6> {data.binbesyuz_uzeri_kampanya}</h6>
                <FaRegCheckCircle />
                <h6> {data.uzeri_ucretsiz_kargo}</h6>
                <FaRegCheckCircle /> <h6> {data.ilk_alisverise_ozel_kod_header}</h6>
                <FaRegCheckCircle />
               <h6> {data.kayan_yazi_mobil}</h6>
                <FaRegCheckCircle />
                <h6> {data.binbesyuz_uzeri_kampanya}</h6>
                <FaRegCheckCircle /> <h6> {data.uzeri_ucretsiz_kargo}</h6>
                <FaRegCheckCircle />
                <h6> {data.ilk_alisverise_ozel_kod_header}</h6>
              </div>
            </Marquee>
          </div>
          <div className="col-1 d-lg-flex justify-content-end header-item-none ">
            <Language />
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderSectionTwo;
