import React from "react";
import { useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import { Helmet } from "react-helmet";

const SellingContract = () => {
  const { data } = useSelector((state) => state.language);
  const language = useSelector(state => state.language.language);
  const metaDescriptionTag = document.querySelector(
    'meta[name="description"]'
  );
  if (metaDescriptionTag) {
    metaDescriptionTag.setAttribute("content", "Haklarınız, yükümlülükleriniz ve iade şartları hakkında detaylı bilgiye buradan ulaşabilirsiniz.");
  } else {
    const newMetaTag = document.createElement("meta");
    newMetaTag.name = "description";
    newMetaTag.content = "Haklarınız, yükümlülükleriniz ve iade şartları hakkında detaylı bilgiye buradan ulaşabilirsiniz.";
    document.head.appendChild(newMetaTag);
  }
  return (
    <>
    <Helmet>
        <title>{data.mesafeli_satis_sozlesmesi}| Perfume Atelier</title>
       
        <link
          rel="canonical"
          href={`https://www.perfumeatelier.com.tr/${language}/mesafeli-satis-sozlesmesi`}
        />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>{" "}
      <div className="container-fluid main-space">
        <div className="col-12 d-flex align-items-start">
          <div className="">
            <h1 className="mb-3">
              <b> {data.mesafeli_satis_sozlesmesi}</b>
            </h1>
            {ReactHtmlParser(data.mesafeli_satis_inner)}
          </div>
        </div>
      </div>
    </>
  );
};

export default SellingContract;
