import React from 'react'
import { useSelector } from "react-redux";
import { removeTurkishChars } from './urlUtils';
const Category = ({
    category,
    openCategories,
    toggleCategory,
    depth,
    productCount,
    selectedCategory,
  }) => {
    const language = useSelector(state => state.language.language);

    const paddingLeft = category.depth * 5;
  
    const isSelected =
      selectedCategory && selectedCategory.category_id === category.category_id;
  
    return (
      <li
        className={`d-flex flex-column align-items-start`}
        key={category.category_id}
        style={{ marginLeft: `${paddingLeft}px` }}
      >
        {category.children && category.children.length > 0 ? (
          <div>
            <span
              style={{ fontWeight: 650 }}
              onClick={() => toggleCategory(category)}
              className={`d-flex ${isSelected ? "selected-category" : ""}`}
            >
              {language == 'en' ? category.category_name_en : category.category_name}
             
            </span>
            {openCategories.includes(category.category_id) && (
              <ul className="navbar-nav mb-2 mb-lg-0 d-flex mb-ul">
                {category.children.map((childCategory) => (
                  <Category
                    key={childCategory.category_id}
                    category={childCategory}
                    openCategories={openCategories}
                    toggleCategory={toggleCategory}
                  />
                ))}
              </ul>
            )}
          </div>
        ) : (
          <span
            style={category.depth == 1 ? { fontWeight: 650 } : {}}
            onClick={() => toggleCategory(category)}
            className={` ${isSelected ? "selected-category" : ""}`}
          >
              {language == 'en' ? category.category_name_en : category.category_name}
              </span>
        )}
      </li>
    );
  };

export default Category