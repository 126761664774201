import React, { useState, useEffect } from "react";
import "../assets/css/products.css";
import { IoIosArrowDown } from "react-icons/io";
import noImage from "../assets/img/no_pic.webp";
import { Link } from "react-router-dom";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";
import FavoriteButton from "../utils/FavoriteButton";
import { removeTurkishChars } from "../utils/urlUtils";
import config from "../config";
import AddBasketButton from "../utils/AddBasketButton";
import { useSelector } from "react-redux";

const { REACT_APP_BASE_URL } = process.env;

const NewestMainComponent = () => {
  const [categories, setCategories] = useState([]);
  const [sortOption, setSortOption] = useState("");
  const language = useSelector(state => state.language.language);
  const { data } = useSelector((state) => state.language);

  const categoryName = "En Yeniler";
 
  const metaDescriptionTag = document.querySelector(
    'meta[name="description"]'
  );
  if (metaDescriptionTag) {
    metaDescriptionTag.setAttribute("content", language == 'en' ? "Our Newest collection brings together the latest trends and the freshest scents. Rediscover yourself with our innovative and striking perfumes and stand out at every moment." : "En Yeniler koleksiyonumuz, son trendleri ve en taze kokuları bir araya getiriyor. Yenilikçi ve çarpıcı parfümlerimizle kendinizi yeniden keşfedin ve her anınızda öne çıkın.");
  } else {
    const newMetaTag = document.createElement("meta");
    newMetaTag.name = "description";
    newMetaTag.content = language == 'en' ? "Our Newest collection brings together the latest trends and the freshest scents. Rediscover yourself with our innovative and striking perfumes and stand out at every moment." : "En Yeniler koleksiyonumuz, son trendleri ve en taze kokuları bir araya getiriyor. Yenilikçi ve çarpıcı parfümlerimizle kendinizi yeniden keşfedin ve her anınızda öne çıkın.";
    document.head.appendChild(newMetaTag);
  }
  const [showFilter, setShowFilter] = useState(false);

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  useEffect(() => {
    axios
      .post(
        REACT_APP_BASE_URL + "web/read/category-find",
        {
          search: categoryName,
        },
        {
          headers: {
            xsfr: config.secretKey,
          },
        }
      )
      .then((response) => {
        setCategories(response.data.categories[0].products);
      })
      .catch((error) => {
        console.error("Err", error);
      });
  }, []);

  const toggleSortOrder = (option) => {
    setSortOption(option);
  };

  useEffect(() => {
    const sortedProducts = sortProducts(categories, sortOption);
    setCategories(sortedProducts);
  }, [sortOption]);

  const sortProducts = (products, option) => {
    return [...products].sort((a, b) => {
      switch (option) {
        case "En Düşük Fiyat":
          return (
            parseFloat(a.comb_prices[0].price) -
            parseFloat(b.comb_prices[0].price)
          );
        case "En Yüksek Fiyat":
          return (
            parseFloat(b.comb_prices[0].price) -
            parseFloat(a.comb_prices[0].price)
          );
        default:
          return 0;
      }
    });
  };
  return (
    <>
      <Helmet>
        <title>En Yeniler | Perfume Atelier</title>
        <meta
          name="description"
          content="En Yeniler koleksiyonumuz, son trendleri ve en taze kokuları bir araya getiriyor. Yenilikçi ve çarpıcı parfümlerimizle kendinizi yeniden keşfedin ve her anınızda öne çıkın."
        />
        <link rel="canonical" href={`https://www.perfumeatelier.com.tr/${language}/en-yeniler`} />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <div className="products-main container-fluid main-space">
        <div className="row">
          <div className="col-12 ">
            <div className="col-lg-12 col-md-12 col-12 d-lg-flex d-md-flex justify-content-between align-items-center">
              <div className="col-8 d-flex">
                <h1 className="page-title">
                  <b className="m-1"> {data.en_yeniler}</b>
                </h1>
              </div>
              <div className="col-xl-2 col-md-4 col-8 position-relative">
                <button
                  className="button-type-four w-100 text-start d-flex align-items-center justify-content-between"
                  onClick={toggleFilter}
                >
                  {data.onerilen_siralama} <IoIosArrowDown />
                </button>
                {showFilter && (
                  <div
                    className={`card-type-two filter-section  mt-2 w-100 ${
                      showFilter ? "fade-top" : ""
                    }`}
                  >
                    <p
                      onClick={() => toggleSortOrder("En Düşük Fiyat")}
                      className="sorted-select-hover"
                    >
 {data.en_dusuk_fiyat}                    </p>
                    <p
                      onClick={() => toggleSortOrder("En Yüksek Fiyat")}
                      className="sorted-select-hover"
                    >
 {data.en_yuksek_fiyat}                    </p>
                  </div>
                )}
              </div>
            </div>
            {categories.length > 0 ? (
              <div className="row mt-3">
                {categories.length > 0 &&
                  categories.map((product, index) => (
                    <div
                      className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-6 mb-3"
                      key={index}
                    >
                      <div className="card-type-one p-2 mb-4 d-flex align-items-center justify-content-between flex-column h-100">
                        <div className="col-12 d-flex justify-content-end">
                          <FavoriteButton product={product} />
                        </div>

                        <Link
                           to={`/${language}/urun-detay/${
                            removeTurkishChars(
                              language == 'en' ?  product.page_url_en.toLowerCase().replace(/ /g, "-") : 
                            product.page_url.toLowerCase().replace(/ /g, "-")
                          )}?attribute=${
                            language=='en' ? 
                            product.comb_prices[0]?.comb_atts.length > 0 &&
                            product.comb_prices[0]?.comb_atts[0].attribute_value.value_name_en.replace(
                              / /g,
                              "-"
                            ) :  product.comb_prices[0]?.comb_atts.length > 0 &&
                            product.comb_prices[0]?.comb_atts[0].attribute_value.value_name.replace(
                              / /g,
                              "-"
                            )
                          }`}
                        >
                          <div className="col-12 d-flex justify-content-center products-img">
                            <img
                              crossOrigin="anonymous"
                              src={
                                product.product_images &&
                                product.product_images.length > 0
                                  ? REACT_APP_BASE_URL +
                                    product.product_images[0].image_url
                                  : noImage
                              }
                              alt={
                                product.product_images &&
                                product.product_images.length > 0
                                  ? product.product_name
                                  : "perfume atelier"
                              }
                              onLoad={(e) => e.target.classList.add("loaded")}
                            />
                          </div>
                          <h2 className="title-main">
                            <b>{language == 'en' ? product.product_name_en :product.product_name}</b>
                          </h2>
                          <p style={{ color: "#bdbdbd" }}>
                            <span>
                              {language == 'en' ? product.comb_prices[0]?.comb_atts.length > 0 &&
                                product.comb_prices[0]?.comb_atts[0]
                                  .attribute_value.value_name_en :product.comb_prices[0]?.comb_atts.length > 0 &&
                                product.comb_prices[0]?.comb_atts[0]
                                  .attribute_value.value_name}
                            </span>{" "}
                          </p>
                          <h6>
                            <b>
                              {product.comb_prices[0]?.price ? (
                                <b>{product.comb_prices[0].price}₺</b>
                              ) : (
                                "bulunamadı"
                              )}
                            </b>
                          </h6>
                        </Link>

                        <AddBasketButton product={product} />
                      </div>
                    </div>
                  ))}
              </div>
            ) : (
              <div className="loader-div"></div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default NewestMainComponent;
