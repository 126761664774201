import React from "react";
import { useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import { Helmet } from "react-helmet";
const PrivacySecurity = () => {
  const { data } = useSelector((state) => state.language);
  const language = useSelector(state => state.language.language);
  return (
    <>
    <Helmet>
        <title>{data.gizlilik_guvenlik} | Perfume Atelier</title>
        <meta
          name="description"
          content="Perfume Atelier ile Kokuyu Hissedin!"
        />
        <link
          rel="canonical"
          href={`https://www.perfumeatelier.com.tr/${language}/gizlilik-guvenlik`}
        />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>{" "}
      <div className="container-fluid main-space">
        <div className="col-12 d-flex align-items-start">
          <div className="">
            <h1 className="mb-3">
              <b>{data.gizlilik_guvenlik} </b>
            </h1>
            {ReactHtmlParser(data.gizlilik_guvenlik_inner)}

          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacySecurity;
