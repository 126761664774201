import React from "react";
import "../assets/css/homeSectionTwo.css";
import img1 from "../assets/img/sabun_bento.webp";
import img2 from "../assets/img/mum_banner.webp";
import img4 from "../assets/img/bento_04.webp";
import img3 from "../assets/img/hediye_seti.jpg";
import img5 from "../assets/img/ortam.webp";
import { useSelector } from "react-redux";

const HomeSectionTwo = () => {
  const language = useSelector((state) => state.language.language);
  const { data } = useSelector((state) => state.language);

  return (
    <div className="home-section-two container-fluid main-space mt-5">
      <div className="col-12 d-flex flex-lg-row flex-column justify-content-center align-items-lg-stretch align-items-center  gap-lg-5">
        <div className="col-lg-4 col-10 d-lg-flex flex-column align-items-start gap-5">
          <div className="image-with-text">
            <img src={img1} alt="Perfume Atelier Sabun" />
            <div className="centered-text">
              <h2> {data.sabun}</h2>
              <a
                href={
                  language == "en"
                    ? `/${language}/urunler?kategori=Personal-Care_1-Liter-Liquid-Soap`
                    : `/${language}/urunler?kategori=Kişisel-Bakım_1-Litre-Sıvı-Sabun`
                }
              >
                {" "}
                <button className="button-type-three" id="homesectiontwo_1_btn">
                  {data.kesfet}{" "}
                </button>
              </a>
            </div>
          </div>
          <div className="image-with-text mt-lg-0 mt-3">
            <img src={img2} alt="Perfume Atelier Mum" />
            <div className="centered-text">
              <h2> {data.mum}</h2>
              <a
                href={
                  language == "en"
                    ? `/${language}/urunler?kategori=Candles`
                    : `/${language}/urunler?kategori=Mum`
                }
              >
                {" "}
                <button className="button-type-three" id="homesectiontwo_2_btn">
                  {data.kesfet}
                </button>
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-10 d-lg-flex flex-column align-items-end">
          <div className="image-with-text">
            <img
              className="mt-lg-0 mt-3"
              src={img3}
              alt="Perfume Atelier Yastık Parfümü"
            ></img>
            <div className="centered-text">
              <h2> {data.hediye_seti}</h2>
              <a
                href={
                  language == "en"
                    ? `/${language}/urunler/?kategori=Gift-Set`
                    : `/${language}/urunler/?kategori=Hediye-Seti`
                }
              >
                {" "}
                <button className="button-type-three" id="homesectiontwo_3_btn">
                  {data.kesfet}
                </button>
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-10 d-lg-flex flex-column  align-items-end gap-5">
          <div className="image-with-text">
            <img
              className="mt-lg-0 mt-3"
              src={img4}
              alt="Perfume atelier Hair Mist"
            ></img>
            <div className="centered-text">
              <h2> {data.hair_mist}</h2>
              <a
                href={
                  language == "en"
                    ? `/${language}/urunler?kategori=Personal-Care_Hair-Mist`
                    : `/${language}/urunler?kategori=Kişisel-Bakım_Hair-Mist`
                }
              >
                {" "}
                <button className="button-type-three" id="homesectiontwo_4_btn">
                  {data.kesfet}
                </button>
              </a>
            </div>
          </div>
          <div className="image-with-text mt-lg-0 mt-3">
            <img src={img5} alt="Perfume Atelier Ortam Kokuları"></img>
            <div className="centered-text">
              <h2> {data.ortam_kokulari}</h2>
              <a
                href={
                  language == "en"
                    ? `/${language}/urunler?kategori=Ambient-Odours`
                    : `/${language}/urunler?kategori=Ortam-Kokuları`
                }
              >
                {" "}
                <button className="button-type-three" id="homesectiontwo_4_btn">
                  {data.kesfet}
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeSectionTwo;
