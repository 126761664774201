import React from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

const MisionComponent = () => {
  const { data } = useSelector((state) => state.language);
  const language = useSelector(state => state.language.language);
  const metaDescriptionTag = document.querySelector(
    'meta[name="description"]'
  );
  if (metaDescriptionTag) {
    metaDescriptionTag.setAttribute("content", "Bu sayfaya ulaşmak için gösterdiğiniz ilgi için teşekkür ederiz. Anlatmayı en sevdiğim hikaye Perfume ATELİER’in hikayesidir.");
  } else {
    const newMetaTag = document.createElement("meta");
    newMetaTag.name = "description";
    newMetaTag.content = "Bu sayfaya ulaşmak için gösterdiğiniz ilgi için teşekkür ederiz. Anlatmayı en sevdiğim hikaye Perfume ATELİER’in hikayesidir.";
    document.head.appendChild(newMetaTag);
  }
  return (
    <> <Helmet>
      <title>{data.misyonumuz} | Perfume Atelier</title>
      <link
        rel="canonical"
        href={`https://wwww.perfumeatelier.com.tr/${language}/misyonumuz`}
      />
      <link rel="icon" href="/favicon.ico" />
    </Helmet>  <div className="main-space">
        <div className="col-12 d-flex align-items-start">
          <div className="">
            <h1 className="mb-3">
              <b> {data.misyonumuz}</b>
            </h1>
            <h2> {data.misyon_baslik}</h2>
            <p>
            {data.misyon_aciklama_1}
            </p>
            <p>   {data.misyon_aciklama_2}</p>
            <p>  {data.misyon_aciklama_3}</p>
          </div>
        </div>
      </div></>

  );
};

export default MisionComponent;
