import React from "react";
import ReactHtmlParser from "react-html-parser";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

const TermsOfUse = () => {
  const { data } = useSelector((state) => state.language);
  const language = useSelector(state => state.language.language);
  const metaDescriptionTag = document.querySelector(
    'meta[name="description"]'
  );
  if (metaDescriptionTag) {
    metaDescriptionTag.setAttribute("content", "Hizmetlerimizden yararlanırken uymanız gereken kurallar ve koşullar hakkında detaylı bilgiye buradan ulaşabilirsiniz.");
  } else {
    const newMetaTag = document.createElement("meta");
    newMetaTag.name = "description";
    newMetaTag.content = "Hizmetlerimizden yararlanırken uymanız gereken kurallar ve koşullar hakkında detaylı bilgiye buradan ulaşabilirsiniz.";
    document.head.appendChild(newMetaTag);
  }
  return (
    <>
     <Helmet>
        <title>{data.kullanim_sartlari} | Perfume Atelier</title>
        <link
          rel="canonical"
          href={`https://www.perfumeatelier.com.tr/${language}/kullanim-sartlari`}
        />

        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <div className="container-fluid main-space">
        <div className="col-12 d-flex align-items-start">
          <div className="">
            <h1 className="mb-3">
              <b> {data.kullanim_sartlari}</b>
            </h1>
           {ReactHtmlParser(data.kullanim_sartlari_inner)}
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsOfUse;
