import React from "react";
import storesData from "../data/stores.json";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

const StoresComponent = () => {
  const { data } = useSelector((state) => state.language);
  const language = useSelector(state => state.language.language);
  const metaDescriptionTag = document.querySelector(
    'meta[name="description"]'
  );
  if (metaDescriptionTag) {
    metaDescriptionTag.setAttribute("content", "Size en yakın mağazamızı bulun! Dünya genelindeki tüm mağazalarımızın adresleri, çalışma saatleri ve iletişim bilgilerine kolayca ulaşın.");
  } else {
    const newMetaTag = document.createElement("meta");
    newMetaTag.name = "description";
    newMetaTag.content = "Size en yakın mağazamızı bulun! Dünya genelindeki tüm mağazalarımızın adresleri, çalışma saatleri ve iletişim bilgilerine kolayca ulaşın.";
    document.head.appendChild(newMetaTag);
  }
  return (
    <>
      <Helmet>
        <title>{data.magazalarimiz} | Perfume Atelier</title>
        <link
          rel="canonical"
          href={`https://www.perfumeatelier.com.tr/${language}/magazalarimiz`}
        />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>{" "}
      <div className="stores-main container-fluid main-space">
        <h1>
          <b> {data.magazalarimiz}</b>
        </h1>
        <div>
          <div className="d-lg-flex d-md-flex justify-content-center p-0 flex-wrap mb-3 mt-3">
            {storesData.map((store, index) => (
              <div
                key={store.store_no}
                className="col-lg-6 col-md-6 col-12 pe-lg-3 pe-md-3 mb-3"
              >
                <div className="card-type-two text-start d-lg-flex p-lg-0 p-3 h-100">
                  <div className="col-lg-5 d-flex align-items-center justify-content-center">
                    <img
                      src={store.img}
                      className="p-lg-2 p-4"
                      alt={store.title}
                    />
                  </div>
                  <div className="col-lg-7 d-flex justify-content-center flex-column p-lg-2">
                    <h3>{store.title}</h3>
                    <p> {store.adres}</p>
                    <p> {store.tel}</p>
                    <a href={store.link} target="_blank">
                      {" "}
                      <button
                        className="button-type-two"
                        style={{ width: "max-content" }}
                      >
                         {data.yol_tarifi}
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default StoresComponent;
