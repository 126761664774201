import { SiHomebridge } from "react-icons/si";
import { CgSmartHomeHeat } from "react-icons/cg";
import { BsBorderStyle } from "react-icons/bs";
import React, { useState } from "react";
import { FaCaravan } from "react-icons/fa";
import "../assets/css/orderTrackingDetails.css";
import fatura from "../assets/img/invoice_page.png";

function OrderTrackingDetailsComponent() {
  const [showInvoice, setShowInvoice] = useState(false);

  const handleInvoiceClick = () => {
    setShowInvoice(!showInvoice);
  };
  return (
    <>
      <div className="col-lg-10 col-md-12 col-12 container-fluid main-space order-tracking-details-main">
        <div className="container">
          <h2 className="text-center">Sipariş Takip</h2>
          <div className="row mt-5">
            <div className="col-12  ">
              <div className="row justify-content-between">
                <div className="order-tracking completed">
                  <span className="is-complete" />
                  <p>
                    Sipariş hazırlandı
                    <br />
                    <span>Mon, June 24</span>
                  </p>
                </div>
                <div className="order-tracking completed">
                  <span className="is-complete" />
                  <p>
                    Kargoya verildi
                    <br />
                    <span>Tue, June 25</span>
                  </p>
                </div>
                <div className="order-tracking">
                  <span className="is-complete" />
                  <p>
                    Teslim edildi
                    <br />
                    <span>Fri, June 28</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-5 cargo-details">
          <div className="row">
            <div className="col-lg-12">
              <div className="d-xl-flex justify-content-between">
                <div className="d-flex align-items-center">
                  <div className="">
                    <FaCaravan />
                  </div>
                  <div className="">
                    <h4>Kargo Takip:</h4>
                    <p>https://www.yurticikargo.com/</p>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="">
                    <SiHomebridge />
                  </div>
                  <div className="">
                    <h4>Kargo Firması:</h4>
                    <p>Yurtiçi</p>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="">
                    <CgSmartHomeHeat />
                  </div>
                  <div className="">
                    <h4>Kargo Numarası:</h4>
                    <p>TSFWTK24Z6GDCN3E</p>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="">
                    <BsBorderStyle />
                  </div>
                  <div className="">
                    <h4>Sipariş Numarası:</h4>
                    <p>TSFWTK24Z6GDCN3E</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-lg-12 d-flex align-items-center justify-content-center flex-column">
              <div className=" text-center">
                <div onClick={handleInvoiceClick}>
                  <button className="button-type-two">
                    {showInvoice ? "Faturayı Kapat" : "Faturayı Görüntüle"}
                  </button>
                </div>
              </div>
              {showInvoice && (
                <div className="col-lg-7">
                  <img className="w-100" src={fatura} alt="Invoice" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OrderTrackingDetailsComponent;
