import React from "react";
import { Helmet } from "react-helmet";
import blogsData from "../data/blog.json";
import ReactHtmlParser from "react-html-parser";
import { useParams } from "react-router-dom";
import { removeTurkishChars } from "../utils/urlUtils";
import { useSelector } from "react-redux";

const BlogSingleComponent = () => {
  const { title } = useParams();
  const language = useSelector((state) => state.language.language);

  const blogPost = blogsData.find(
    (blog) =>
      removeTurkishChars(blog.title).replace(/\s+/g, "-").toLowerCase() ===
      title
  );

  if (!blogPost) {
    return <p>Blog post not found</p>;
  }

  return (
    <>
      <Helmet>
        <title>{blogPost.title} | Perfume Atelier</title>
        <meta
          name="description"
          content="Perfume Atelier ile Kokuyu Hissedin!"
        />
        <link
          rel="canonical"
          href={`https://www.perfumeatelier.com.tr/${language}/blog/${removeTurkishChars(
            blogPost.title
          )
            .replace(/\s+/g, "-")
            .toLowerCase()}`}
        />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <div className="container-fluid bg-blog main-space">
        <div className="col-12">
          <h1>{blogPost.title}?</h1>
          <br />{" "}
          <img
            className="main-radius"
            src={blogPost.img}
            alt={blogPost.title}
          ></img>
          <p className="mt-4">{ReactHtmlParser(blogPost.info)}</p>
        </div>
      </div>
    </>
  );
};

export default BlogSingleComponent;
