import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import noimg from "../assets/img/no_pic.webp";
import "../assets/css/coupon.css";
import config from "../config";
import { useSelector } from "react-redux";

const { REACT_APP_BASE_URL } = process.env;

const Coupon = () => {
  const [coupons, setCoupons] = useState([]);
  const token = useSelector((state) => state.token);
  const userDetails = useSelector((state) => state.account.userDetails);
  const { data } = useSelector((state) => state.language);

  useEffect(() => {
    axios
      .post(
        REACT_APP_BASE_URL + "coupon/read",
        {
          user_id: userDetails.user_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            xsfr: config.secretKey,
            Authorization: `Bearer ${token.tokenAuth}`,
          },
        }
      )
      .then((response) => {
        setCoupons(response.data.response);
      })
      .catch((error) => {
        console.error("Err", error);
      });
  }, [userDetails.user_id, token.tokenAuth]);

  const copyToClipboard = (code) => {
    navigator.clipboard
      .writeText(code)
      .then(() => {
        toast.success(data.kupon_kopyalandi);
      })
      .catch((error) => {
        toast.error(data.kupon_kopyalanamadi);
        console.error("Kopyalanamadı!", error);
      });
  };

  return (
    <div className="coupon-main">
      <h2 className="user-account-inner-title">
        <b>{data.kuponlar} </b>
      </h2>

      <div className="">
        {coupons.length === 0 ? (
          <p>{data.kupon_bulunamadi} </p>
        ) : (
          coupons.map((coupon) => (
            <div className="col-12 mb-3" key={coupon.coupon_id}>
              <div className="card-type-coupon d-flex flex-lg-row flex-column  justify-content-center  align-items-center p-2 text-bold">
                <div className="col-lg-3 col-md-12 col-12 d-flex justify-content-center">
                  <img
                    crossOrigin="anonymous"
                    src={
                      coupon.coupon_img
                        ? `${REACT_APP_BASE_URL}${coupon.coupon_img}`
                        : noimg
                    }
                    alt="Coupon"
                  />
                </div>
                <div className="col-lg-5 col-md-12 col-12 d-flex flex-column justify-content-cente align-items-lg-start align-items-center text-lg-start text-center">
                  <h5>
                    <b>{coupon.coupon_name}</b>
                  </h5>
                  <p
                    className="p-0 m-0"
                    dangerouslySetInnerHTML={{
                      __html: coupon.coupon_description,
                    }}
                  ></p>
                  {coupon.start_date !== "1970-01-01T00:00:00.000Z" &&
                  coupon.end_date !== "1970-01-01T00:00:00.000Z" ? (
                    <>
                      {" "}
                      <span>
                        {new Date(coupon.start_date).toLocaleDateString()}{" "}
                        -&nbsp;
                        {new Date(coupon.end_date).toLocaleDateString()}
                        &nbsp; {data.tarihleri_arasinda_gecerlidir}{" "}
                      </span>
                    </>
                  ) : (
                    ""
                  )}
                  {console.log(coupon)}

                </div>
                <div className="col-lg-4 col-md-12 col-12 d-flex flex-column justify-content-center align-items-center">
                  <div className="d-flex align-items-center">
                    <h2>
                      <b>
                        {coupon.coupon_value}
                        {coupon.coupon_type}
                      </b>
                    </h2>
                    <p className="p-2">{data.indirim} </p>
                  </div>
                  <h4
                    className="coupon-inner-bg p-3"
                    onClick={() => copyToClipboard(coupon.coupon_code)}
                    style={{ cursor: "pointer" }}
                    title="Kodu kopyalamak için tıklayın"
                  >
                    <b>
                      {data.kod}: {coupon.coupon_code}
                    </b>
                  </h4>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default Coupon;
