import React from "react";
import { useSelector } from "react-redux";

const Subscriber = () => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const language = useSelector(state => state.language.language);
  const { data } = useSelector((state) => state.language);

  return (
    <div className="bg-subs p-5 mt-3 mb-3 ">
      <h2 className="text-center">
      {data.home_title_5}      </h2>
      <div className="d-flex justify-content-center mt-1">
      
        <a className="mt-3"  href={`/${language}/user/account-details`}>
       
           <button className="button-type-four ms-lg-2 ms-md-2 ms-0" type="button" id="subscribe_btn">
          <p className="m-2" style={{fontSize:"20px"}}> {data.simdi_abone_olun}</p>
        </button>
        </a>
     
      </div>
    </div>
  );
};

export default Subscriber;
