import React from "react";
import { useSelector } from "react-redux";
import { IoClose } from "react-icons/io5";
import { removeFromFavorites } from "../redux/actions/cartActions";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import noImage from "../assets/img/no_pic.webp";
import { Link } from "react-router-dom";
import { removeTurkishChars } from "../utils/urlUtils";

const { REACT_APP_BASE_URL } = process.env;

const Favorites = () => {
  const favorites = useSelector((state) => state.cart.favorites);
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language.language);
  const { data } = useSelector((state) => state.language);

  const handleRemoveFromFavorites = (e, item) => {
    e.preventDefault();
    dispatch(removeFromFavorites(item));
    toast.success(data.urun_favorilerden_kaldirildi);
  };

  return (
    <div className="favorites-main">
      <h2 className="user-account-inner-title">
        <b>{data.favorilerim} </b>
      </h2>

      {favorites.length === 0 ? (
        <p>{data.favori_urun_baslik} </p>
      ) : (
        <div className="row">
          {favorites.map((item) => (
            <div
              key={item.product_id}
              className="col-lg-4 col-md-6 col-12 mb-3"
            >
              <div className="card-type-one d-flex flex-column justify-content-center p-2">
                <div className="col-12 d-flex justify-content-end">
                  <IoClose
                    onClick={(e) => handleRemoveFromFavorites(e, item)}
                  />
                </div>

                <Link
                  to={`/${language}/urun-detay/${removeTurkishChars(
                    item.page_url.toLowerCase().replace(/ /g, "-")
                  )}`}
                >
                  {" "}
                  {item.product_image_url ? (
                    <img
                      crossOrigin="anonymous"
                      src={REACT_APP_BASE_URL + item.product_image_url}
                      alt={item.product_name}
                    />
                  ) : (
                    <img alt="perfume atelier" src={noImage}></img>
                  )}
                  <h2 className="title-main">
                    <b>{item.product_name}</b>
                  </h2>
                  <p>{item.value_name}</p>
                  <h6>
                    <b>{item.product_price} tl</b>
                  </h6>
                  <button className="button-type-two w-100">
                  {data.urunu_incele}
                  </button>
                </Link>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Favorites;
