import React, { useState, useRef, useEffect } from "react";
import logo from "../assets/img/logo.svg";
import { slide as Menu } from "react-burger-menu";
import "../assets/css/mobileMenu.css";
import payment from "../assets/img/payment.webp";
import userIcon from "../assets/img/icons/hesap.svg";
import userIconHover from "../assets/img/icons/perfume_icon-06.svg";
import sepetIcon from "../assets/img/icons/siparis-sepet.svg";
import sepetIconHover from "../assets/img/icons/perfume_icon-07.svg";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import ShoppingCart from "./ShoppingCart";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import axios from "axios";
import HeaderSectionTwo from "./HeaderSectionTwo";
import { FaInstagram } from "react-icons/fa";
import { RiFacebookFill } from "react-icons/ri";
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import config from "../config";
import searchIcon from "../assets/img/icons/perfume_icon-02.svg";
import searchIconHover from "../assets/img/icons/perfume_icon-05.svg";
import SearchBar from "./SearchBar";
import { useSelector } from "react-redux";
import Language from "./Language";

const { REACT_APP_BASE_URL } = process.env;

const MobileMenu = ({ itemCount, isLoggedIn, categoryData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [hasMore, setHasMore] = useState(true);
  const [startIndex, setStartIndex] = useState(0);
  const [openChild, setOpenChild] = useState(null);
  const [openCategory, setOpenCategory] = useState(null);
  const [isProductsOpen, setIsProductsOpen] = useState(false);
  const [isScentsOpen, setIsScentsOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const language = useSelector(state => state.language.language);
  const { data } = useSelector((state) => state.language);

  const navigate = useNavigate();

  const [isHovered, setIsHovered] = useState(null);
  const handleIconHover = (iconName) => {
    setIsHovered(iconName);
  };

  const handleIconLeave = () => {
    setIsHovered(null);
  };
  const toggleProducts = () => {
    setIsProductsOpen(!isProductsOpen);
    setIsScentsOpen(false);
  };
  const handleClick = () => {
    setSearchOpen(!isSearchOpen);
  };
  const toggleScents = () => {
    setIsScentsOpen(!isScentsOpen);
    setIsProductsOpen(false);
  };

  const [isSearchOpen, setSearchOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const toggleChild = (childId) => {
    setOpenChild(openChild === childId ? null : childId);
  };

  const toggleCategory = (categoryId) => {
    setOpenCategory(openCategory === categoryId ? null : categoryId);
  };

  function encodeSentence(sentence) {
    return encodeURIComponent(sentence);
  }

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleCategoryClick = (
    category,
    isChild = false,
    isSubchild = false
  ) => {
    const paths = language == 'en' ? category.category_path_en : category.category_path
    const replaceCategory = paths
      .replaceAll(" ", "-")
      .replaceAll("/", "_");
    const encodedSentence = encodeURIComponent(replaceCategory);
    setSelectedCategory(category);

    let url = `/${language}/urunler/?kategori=${encodedSentence.slice(
      0,
      encodedSentence.length - 1
    )}`;

    window.location.replace(url);
  };

  return (
    <>
      <div className="mobile-menu">
        <HeaderSectionTwo />
        <nav className=" navbar navbar-expand-lg border bg-body-tertiary justify-content-center">
          <div className="container" style={{ maxWidth: "100%" }}>
            <div className="col-1">
              <button
                className="connectedbtn btn fs-1 p-0"
                type="button"
                id="toggle_btn"
                onClick={toggleMenu}
              >
                <span className={isMenuOpen ? "d-none" : "navbar-toggler"}>
                  &#9776;
                </span>
                <span className={isMenuOpen ? "navbar-toggler" : "d-none"}>
                  &times;
                </span>
              </button>
            </div>
            <div className="col-2 mobil-lang">
         <Language/>
         </div>
            <div className="col-4 d-flex justify-content-center">
              <Link to={`/${language}/`}>
             
                <img className="header-logo" src={logo} alt="header-logo"/>
              </Link>
            </div>

            <div className="col-3 d-flex align-items-end justify-content-start justify-content-md-center">
              <div className="col-1 d-flex justify-content-start align-items-center gap-3 gap-md-3 header-svg">
                
                <div
                  className="d-flex align-items-center"
                  id="search-main"
                  onClick={handleClick}
                  onMouseEnter={() => handleIconHover("search")}
                  onMouseLeave={handleIconLeave}
                >
                  <img
                    className="svg-icon"
                    src={isHovered === "search" ? searchIconHover : searchIcon}
                    alt="search-icon"
                  />
                </div>{" "}
                {isLoggedIn ? (
                  <Link
                    className="d-flex"
                    aria-label="user-account-details"
                    to={`/${language}/user/account-details`}
                    
                    onMouseEnter={() => handleIconHover("user")}
                    onMouseLeave={handleIconLeave}
                  >
                    <img
                      className="svg-icon"
                      src={isHovered === "user" ? userIconHover : userIcon}
                      alt="user-icon"
                    />
                  </Link>
                ) : (
                  <Link
                    className="d-flex"
                    aria-label="user-account-details"
                    to={`/${language}/login`}
                    onMouseEnter={() => handleIconHover("user")}
                    onMouseLeave={handleIconLeave}
                  >
                    <img
                      className="svg-icon"
                      src={isHovered === "user" ? userIconHover : userIcon}
                      alt="user-icon"
                    />
                  </Link>
                )}
                <div
                  className="d-flex align-items-center"
                  onClick={toggleModal}
                  onMouseEnter={() => handleIconHover("sepet")}
                  onMouseLeave={handleIconLeave}
                >
                  <img
                    className="svg-icon"
                    src={isHovered === "sepet" ? sepetIconHover : sepetIcon}
                    alt="sepet-icon"
                  />

                  <span onClick={toggleModal} className="badge bg-black">
                    {itemCount}
                  </span>
                </div>
              </div>
              <ShoppingCart isOpen={isModalOpen} onClose={toggleModal} />
            </div>
          </div>
        </nav>
        <div
          className={`header-section-none ${
            isSearchOpen ? "visible" : "hidden"
          }`}
        >
          <SearchBar
            isSearchOpen={isSearchOpen}
            onClose={() => setSearchOpen(false)}
          />
        </div>
      </div>

      <Menu
        isOpen={isMenuOpen}
        onStateChange={({ isOpen }) => setIsMenuOpen(isOpen)}
        left
        className="bg-body-tertiary p-4 "
      >
        <div className="collapse navbar-collapse p-2 mb-2  d-flex flex-column justify-content-between">
          <div className="col-12 d-flex justify-content-center mt-3 mb-5">
            <a className="d-flex justify-content-center"  href={`/${language}/`}>
           
              <img style={{width:"50%"}} alt="mobil_logo" src={logo} />
            </a>
          </div>
          <div className="col-12">
            {" "}
            <div className="d-flex justify-content-between">
              {" "}
              <a href={`/${language}/urunler`}>
              
                {" "}
                <h6>
                  <b>{data.urunler} </b>
                </h6>
              </a>
              <span className="arrow d-flex" onClick={toggleProducts}>
                {isProductsOpen ? (
                  <MdOutlineKeyboardArrowDown />
                ) : (
                  <MdOutlineKeyboardArrowRight />
                )}
              </span>
            </div>
            {isProductsOpen && (
              <ul className="navbar-nav mb-2 mb-lg-0 d-flex mb-ul">
                {categoryData.length > 0 &&
                 categoryData.map((category) => {
                  const catName = language == 'en' ? category.category_name_en : category.category_name
                  if (
                    catName == "En Yeniler" || catName == "The Newest"||
                    catName == "Çok Satanlar"  || catName == "Best Sellers"
                  ) {
                  } else {
                      return (
                    category.children && category.children.length > 0 ? (
                      <div key={category.category_id}>
                        <span
                          onClick={() => {
                            toggleCategory(category.category_id);
                          }}
                        >
                          <div className="d-flex justify-content-between" style={{ cursor: "pointer" }}>
                            <h6
                              onClick={() => {
                                handleCategoryClick(category);
                              }}
                            >
                                    {language == 'en' ? category.category_name_en : category.category_name}
                                    </h6>

                            <span className="arrow">
                              {openCategory === category.category_id ? (
                                <MdOutlineKeyboardArrowDown />
                              ) : (
                                <MdOutlineKeyboardArrowRight />
                              )}
                            </span>
                          </div>
                        </span>
                        {openCategory === category.category_id && (
                          <ul>
                            {category.children.map((child) => (
                              <li key={child.category_id}>
                                <span
                                  onClick={() => toggleChild(child.category_id)}
                                >
                                  <div
                                    className="d-flex"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <h6
                                      onClick={() =>
                                        handleCategoryClick(child, true)
                                      }
                                    >
                                                                        {language == 'en' ? child.category_name_en : child.category_name}
                                                                    
                                    </h6>

                                    <span className="arrow ">
                                      {child.children &&
                                      child.children.length > 0 ? (
                                        <MdOutlineKeyboardArrowRight />
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                  </div>
                                </span>
                                {openChild === child.category_id &&
                                  child.children &&
                                  child.children.length > 0 && (
                                    <ul>
                                      {child.children.map((subChild) => (
                                        <li key={subChild.category_id}>
                                          <h6
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              handleCategoryClick(
                                                subChild,
                                                false,
                                                true
                                              )
                                            }
                                          >
                                            {subChild.category_name}
                                          </h6>
                                          {subChild.children &&
                                            subChild.children.length > 0 && (
                                              <ul>
                                                {subChild.children.map(
                                                  (subSubChild) => (
                                                    <li
                                                      key={
                                                        subSubChild.category_id
                                                      }
                                                    >
                                                      {
                                                                                         language == 'en' ?   subSubChild.category_name_en :   subSubChild.category_name
                                                                                       
                                                      }
                                                    </li>
                                                  )
                                                )}
                                              </ul>
                                            )}
                                        </li>
                                      ))}
                                    </ul>
                                  )}
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    ) : (
                      <h6
                        key={category.category_id}
                        onClick={() => handleCategoryClick(category)}
                      >
                                                            {language == 'en' ? category.category_name_en : category.category_name}

                       
                      </h6>
                    ))  }
                  })}
              </ul>
            )}
            <div className="d-flex justify-content-between">
              {" "}
              <a  href={`/${language}/kategoriler`}>
             
                <h6>
                  <b>{data.kategoriler} </b>
                </h6>{" "}
              </a>
              <span className="arrow d-flex" onClick={toggleDropdown}>
                {isDropdownOpen ? (
                  <MdOutlineKeyboardArrowDown />
                ) : (
                  <MdOutlineKeyboardArrowRight />
                )}
              </span>
            </div>
            {isDropdownOpen && (
              <ul className="navbar-nav mb-2 mb-lg-0 d-flex mb-ul">
                {[
                  "Patchouli",
                  "Narco",
                  "Bergamot Tea",
                  "Rose Violet",
                  "Amber",
                  "Sandalwood",
                  "Jasmine Tea",
                ].map((category, index) => (
                  <li
                    style={{ cursor: "pointer" }}
                    key={index}
                    className="header-dropdown-item d-flex flex-column align-items-start "
                  >
                    <a
                      href={`/${language}/kategoriler?kategori=${encodeURIComponent(
                        category.replace(/ /g, "-")
                      )}`}
                    >
                     
                      <h6> {category}</h6>
                    </a>
                  </li>
                ))}
              </ul>
            )}
            <a className="mb-2"  href={`/${language}/en-yeniler`}>
           
              <h6>
                <b>{data.en_yeniler} </b>
              </h6>
            </a>
            <a className="mb-2" href={`/${language}/cok-satanlar`}>
            
              <h6>
                <b>{data.cok_satanlar} </b>
              </h6>
            </a>
            <a className="mb-2"  href={`/${language}/hakkimizda`}>
           
              <h6>
                <b>{data.hakkimizda} </b>
              </h6>
            </a>
            <a className="mb-2"  href={`/${language}/sikca-sorulan-sorular`}>
           
              <h6>
                <b>{data.sikca_sorulan_sorular} </b>
              </h6>
            </a>
            <a className="mb-2"  href={`/${language}/magazalarimiz`}>
              <h6>
             
                <b>{data.magazalarimiz} </b>
              </h6>
            </a>
          </div>
         
          <div>
        
            <div className="col-12 mobil-menu-text">
           
              <div className="d-flex gap-3">
                <a
                  target="_blank"
                  rel="noreferrer"
                  aria-label="instagram"
                  href="https://www.instagram.com/perfumeatelier/"
                >
                  <FaInstagram />
                </a>

                <a
                  target="_blank"
                  rel="noreferrer"
                  aria-label="facebook"
                  href="https://www.facebook.com/perfumeatelier/"
                >
                  <RiFacebookFill />
                </a>

                <a
                  target="_blank"
                  rel="noreferrer"
                  aria-label="twitter"
                  href="https://x.com/i/flow/login?redirect_after_login=%2FPA_turkey"
                >
                  <FaXTwitter />
                </a>

                <a
                  target="_blank"
                  rel="noreferrer"
                  aria-label="linkedin"
                  href="https://tr.linkedin.com/company/perfume-atelier?trk=ppro_cprof"
                >
                  <FaLinkedinIn />
                </a>
              </div>

              <br />

              <p className="p-0 m-0">
                <a
                  href="mailto:info@perfumeatelier.com.tr"
                  aria-label="perfume mail"
                >
                  info@perfumeatelier.com.tr
                </a>
              </p>
              <p className="p-0 m-0">
                <a href="tel:+905300121212" aria-label="perfume phone"></a>
                +90 530 012 12 12
              </p>
              <br />
              <p className="">
                Yeni Bağlıca Mah. 1015. Sok. No: 6-1 <br /> Etimesgut / Ankara
              </p>

              <img src={payment} alt="payment"></img>
            </div>
            <div className="col-12 mobil-bottom-inner mt-1">
              <p className="mobil-border-bottom">Made by OsiCrew</p>
              <p className="">Copyright © 2024 | osi | all rights reserved.</p>
            </div>
          </div>
        </div>
      </Menu>
    </>
  );
};

MobileMenu.propTypes = {
  itemCount: PropTypes.number,
  isLoggedIn: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => {
  return {
    itemCount: state.cart.cartItems.length,
    isLoggedIn: state.auth.isLoggedIn,
    categoryData: state.category.data,
  };
};

export default connect(mapStateToProps)(MobileMenu);
