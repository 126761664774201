import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "../assets/css/home-slider.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import img1 from "../assets/img/perfume_slider_02.webp";
import img2 from "../assets/img/banner_06.webp";
import img4 from "../assets/img/perfume_slider.webp";
import img5 from "../assets/img/banner_01.webp";
import img6 from "../assets/img/banner_02.webp";
import img7 from "../assets/img/banner_08.webp";
import img8 from "../assets/img/banner_04.webp";
import img9 from "../assets/img/app_slider.webp";
import img10 from "../assets/img/parfum_sabun.webp";
import logo from "../assets/img/logo-white.svg";
import { useSelector } from "react-redux";

const HomeSlider = () => {
  const language = useSelector((state) => state.language.language);
  const { data } = useSelector((state) => state.language);

  return (
    <div className="home-slider-main">
      <div className="col-12">
        <Swiper
          loop={true}
          navigation={true}
          pagination={{
            clickable: true,
          }}
          modules={[Navigation, Pagination]}
          className="mySwiper"
          clickable={true}
        >
          <SwiperSlide>
            <div className="slide-container">
              <img src={img9} alt="img1"></img>
              <span className="slide-text ">
                <h2 className="text-start text-white fw-lg-bold fw-md-bold slide-main-text">
                  <b>{data.mobil_ozel}</b>
                </h2>
                <h2 className="text-start slide-by-text text-white fw-lg-bold fw-md-bold">
                  {data.indirim_kodu_mobil}
                </h2>
                <h2 className="text-start slide-by-text text-white fw-lg-bold fw-md-bold">
                  mobil15
                </h2>
                <br />
                <img style={{ width: "auto" }} alt="logo" src={logo}></img>
                <div className="col-12 d-flex justify-content-start mt-3">
                  <a href={`/${language}/urunler`}>
                    <button className="button-type-five" id="kesfet_1_btn">
                      {data.kesfet}
                    </button>
                  </a>
                </div>
              </span>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slide-container">
              <img src={img4} alt="img1"></img>
              <span className="slide-text ">
                <h2 className="text-start text-white fw-lg-bold fw-md-bold slide-main-text">
                  <b>{data.ilk_alisverisine_ozel}</b>
                </h2>
                <h2 className="text-start slide-by-text text-white fw-lg-bold fw-md-bold">
                  {data.indirim_kodu}
                </h2>
                <h2 className="text-start slide-by-text text-white fw-lg-bold fw-md-bold">
                  hosgeldin10
                </h2>
                <br />
                <img style={{ width: "auto" }} alt="logo" src={logo}></img>
                <div className="col-12 d-flex justify-content-start mt-3">
                  <a href={`/${language}/urunler`}>
                    <button className="button-type-five" id="kesfet_1_btn">
                      {data.kesfet}
                    </button>
                  </a>
                </div>
              </span>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slide-container">
              <img src={img10} alt="img1"></img>
              <span className="slide-text ">
                <h2 className="text-start text-white p-0 m-0 fw-lg-bold fw-md-bold">
                  {data.slider_7_1}
                </h2>
                <h2 className="text-start slide-by-text p-0 m-0 text-white fw-lg-bold fw-md-bold">
                  {data.slider_7_2}
                </h2>
                
                <img style={{ width: "auto" }} alt="logo" src={logo}></img>
                <div className="col-12 d-flex justify-content-start mt-3">
                <a href={language == "en" ? "/en/urunler/?kategori=Personal-Care_1-Liter-Refil-Liquid-Soap" : "/tr/urunler/?kategori=Kişisel-Bakım_1-Litre-Refil-Sıvı-Sabun"}>
                    <button className="button-type-five" id="kesfet_2_btn">
                      {data.kesfet}
                    </button>
                  </a>
                </div>
              </span>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slide-container">
              <img src={img1} alt="img1"></img>
              <span className="slide-text ">
                <h2 className="text-start text-white p-0 m-0 fw-lg-bold fw-md-bold">
                  {data.slider_1_1}
                </h2>
                <h2 className="text-start slide-by-text p-0 m-0 text-white fw-lg-bold fw-md-bold">
                  {data.slider_1_2}
                </h2>
                <h3 className="text-start slide-by-text text-white fw-lg-bold fw-md-bold">
                  {data.slider_1_3}{" "}
                </h3>
                <img style={{ width: "auto" }} alt="logo" src={logo}></img>
                <div className="col-12 d-flex justify-content-start mt-3">
                  <a href={`/${language}/urunler`}>
                    <button className="button-type-five" id="kesfet_2_btn">
                      {data.kesfet}
                    </button>
                  </a>
                </div>
              </span>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slide-container">
              <img src={img2} alt="img2"></img>
              <span className="slide-text ">
                <h2 className="text-start p-0 m-0 fw-lg-bold fw-md-bold">
                  <b> {data.slider_2_1} </b>
                </h2>
                <h2 className="text-start slide-by-text p-0 m-0 fw-lg-bold fw-md-bold">
                  {data.slider_2_2}
                </h2>
                <h3 className="text-start slide-by-text  fw-lg-bold fw-md-bold">
                  {data.slider_2_3}
                </h3>
                <img style={{ width: "auto" }} alt="logo" src={logo}></img>
                <div className="col-12 d-flex justify-content-start mt-3">
                  <a href={`/${language}/urunler`}>
                    <button className="button-type-five" id="kesfet_3_btn">
                      {data.kesfet}
                    </button>
                  </a>
                </div>
              </span>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slide-container">
              <img src={img5} alt="img2"></img>
              <span className="slide-text ">
                <h2 className="text-start p-0 m-0 fw-lg-bold fw-md-bold">
                  <b> {data.slider_3_1} </b>
                </h2>
                <h2 className="text-start slide-by-text p-0 m-0  fw-lg-bold fw-md-bold">
                  {data.slider_3_2}
                </h2>
                <h3 className="text-start slide-by-text  fw-lg-bold fw-md-bold">
                  {data.slider_3_3}
                </h3>
                <img style={{ width: "auto" }} alt="logo" src={logo}></img>
                <div className="col-12 d-flex justify-content-start mt-3">
                  <a href={`/${language}/urunler`}>
                    <button className="button-type-five" id="kesfet_3_btn">
                      {data.kesfet}
                    </button>
                  </a>
                </div>
              </span>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slide-container">
              <img src={img6} alt="img2"></img>
              <span className="slide-text ">
                <h2 className="text-start p-0 m-0 fw-lg-bold fw-md-bold">
                  <b> {data.slider_4_1} </b>
                </h2>
                <h2 className="text-start slide-by-text  fw-lg-bold fw-md-bold p-0 m-0">
                  {data.slider_4_2}
                </h2>
                <h3 className="text-start slide-by-text  fw-lg-bold fw-md-bold">
                  {data.slider_4_3}
                </h3>
                <img style={{ width: "auto" }} alt="logo" src={logo}></img>
                <div className="col-12 d-flex justify-content-start mt-3">
                  <a href={`/${language}/urunler`}>
                    <button className="button-type-five" id="kesfet_3_btn">
                      {data.kesfet}
                    </button>
                  </a>
                </div>
              </span>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slide-container">
              <img src={img7} alt="img2"></img>
              <span className="slide-text ">
                <h2 className="text-start p-0 m-0 fw-lg-bold fw-md-bold">
                  <b> {data.slider_5_1} </b>
                </h2>
                <h2 className="text-start slide-by-text  fw-lg-bold fw-md-bold p-0 m-0">
                  {data.slider_5_2}
                </h2>
                <h3 className="text-start slide-by-text  fw-lg-bold fw-md-bold">
                  {data.slider_5_3}
                </h3>
                <img style={{ width: "auto" }} alt="logo" src={logo}></img>
                <div className="col-12 d-flex justify-content-start mt-3">
                  <a href={`/${language}/urunler`}>
                    <button className="button-type-five" id="kesfet_3_btn">
                      {data.kesfet}
                    </button>
                  </a>
                </div>
              </span>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slide-container">
              <img src={img8} alt="img2"></img>
              <span className="slide-text ">
                <h2 className="text-start p-0 m-0 fw-lg-bold fw-md-bold">
                  <b> {data.slider_6_1} </b>
                </h2>
                <h2 className="text-start slide-by-text p-0 m-0 fw-lg-bold fw-md-bold">
                  {data.slider_6_2}
                </h2>
                <h3 className="text-start slide-by-text  fw-lg-bold fw-md-bold">
                  {data.slider_6_3}
                </h3>
                <img style={{ width: "auto" }} alt="logo" src={logo}></img>
                <div className="col-12 d-flex justify-content-start mt-3">
                  <a href={`/${language}/urunler`}>
                    <button className="button-type-five" id="kesfet_3_btn">
                      {data.kesfet}
                    </button>
                  </a>
                </div>
              </span>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default HomeSlider;
