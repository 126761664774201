import React, { useState, useEffect } from "react";
import "../assets/css/products.css";
import { IoIosArrowDown } from "react-icons/io";
import { Link} from "react-router-dom";
import noImage from "../assets/img/no_pic.webp";
import FavoriteButton from "../utils/FavoriteButton";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";
import Loading from "../wrappers/Loading";
import smellsData from "../data/smells.json";
import { Helmet } from "react-helmet";
import { removeTurkishChars } from "../utils/urlUtils";
import config from "../config";
import AddBasketButton from "../utils/AddBasketButton";
import { useSelector } from "react-redux";

const { REACT_APP_BASE_URL } = process.env;

const SmellsComponent = () => {
  let location = useLocation();
  const { data } = useSelector((state) => state.language);

  const [productsView, setProductsView] = useState("");
  const [canonical, setCanonical] = useState("/kategoriler");
  const [categoryView, setCategoryView] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [startIndex, setStartIndex] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [activeCategory, setActiveCategory] = useState("");
  const [title, setTitle] = useState(data.kategoriler);
  const [desc, setDesc] = useState("");
  const [smellDesc, setSmellDesc] = useState("");
  const [pageTitle, setPageTitle] = useState(data.urunler);
  const [sortOption, setSortOption] = useState("");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
  const language = useSelector(state => state.language.language);

  const navigate = useNavigate();
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function encodeSentence(sentence) {
    return encodeURIComponent(sentence);
  }

  function decodeSentence(encodedSentence) {
    return decodeURIComponent(encodedSentence);
  }

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = (bols) => {
    axios
      .get(
        `${REACT_APP_BASE_URL}web/read/product?index=${bols ? 0 : startIndex}`,
        {
          headers: {
            xsfr: config.secretKey,
          },
        }
      )
      .then((response) => {
        const newProducts = response.data.response;
        if (newProducts.length === 0) {
          setHasMore(false);
        } else {
          setProducts([...products, ...newProducts]);
          setStartIndex(startIndex + 1);
        }
      })
      .catch((error) => {
      });
  };

  const fetchProductsByCategory = (category) => {
    axios
      .post(
        REACT_APP_BASE_URL + "web/search/product",
        {
          search: category,
        },
        {
          headers: {
            xsfr: config.secretKey,
          },
        }
      )
      .then((response) => {
        const newProducts = response.data.response;
        setProducts(newProducts);
        setStartIndex(0);
        setHasMore(false);
        setCategoryView(newProducts);
      })
      .catch((error) => {
      });
    
  };

  const handleCategoryClick = (category) => {
    setActiveCategory(category);
  
    navigate(`/${language}/kategoriler?kategori=${encodeSentence(category.replace(/ /g, "-"))}`);
  };

  useEffect(() => {
    const getProductsByCategory = () => {
      const query = new URLSearchParams(location.search);
      const categoryParam = query.get("kategori");
      if (categoryParam) {
        const decodedCategory = decodeSentence(categoryParam.replace(/-/g, " "));
        setSelectedCategory(decodedCategory);
        fetchProductsByCategory(decodedCategory);
        setCanonical(`/kategoriler?kategori=${categoryParam}`);
  
        const selectedSmell = smellsData.find(smell => smell.title === decodedCategory);
        if (selectedSmell) {
          setTitle( language == 'en' ? selectedSmell.title_seo_en : selectedSmell.title_seo);
          setDesc( language == 'en' ? selectedSmell.desc_seo_en : selectedSmell.desc_seo);
          setSmellDesc( language == 'en' ? selectedSmell.desc_en : selectedSmell.desc);
          setPageTitle(selectedSmell.title);
          
          const metaDescriptionTag = document.querySelector('meta[name="description"]');
          if (metaDescriptionTag) {
            metaDescriptionTag.setAttribute("content",language == 'en' ? selectedSmell.desc_seo_en : selectedSmell.desc_seo);
          } else {
            const newMetaTag = document.createElement("meta");
            newMetaTag.name = "description";
            newMetaTag.content =  "Yenilikçi ve çekici parfüm koleksiyonumuzla kendinizi ifade edin. En sevdiğiniz markanın eşsiz kokularını keşfedin. Şimdi keşfe çıkın!";
            document.head.appendChild(newMetaTag);
          }
        }
  
      } else {
        setSelectedCategory("");
        fetchProducts();
        setCanonical("/kategoriler");
    
      }
    };
  
    getProductsByCategory();
  
    window.addEventListener("load", getProductsByCategory);
  
    return () => {
      window.removeEventListener("load", getProductsByCategory);
    };
  }, [location.search]);
  
  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  const toggleSortOrder = (e, option) => {
    e.preventDefault();
    setSortOption(option);
    const sortedProducts = sortProducts(categoryView, option);
    setCategoryView(sortedProducts);
  };

  const sortProducts = (products, option) => {
    return [...products].sort((a, b) => {
      switch (option) {
        case "En Düşük Fiyat":
          return (
            parseFloat(a.comb_prices[0].price) -
            parseFloat(b.comb_prices[0].price)
          );
        case "En Yüksek Fiyat":
          return (
            parseFloat(b.comb_prices[0].price) -
            parseFloat(a.comb_prices[0].price)
          );
        default:
          return 0;
      }
    });
  };

  useEffect(() => {
    const sortedProducts = sortProducts(products, sortOption);
    const productsView = (
      <InfiniteScroll
        dataLength={sortedProducts.length}
        next={fetchProducts}
        hasMore={hasMore}
        loader={<Loading />}
        endMessage={<p>Ürünler yüklendi...</p>}
      >
        <div className={`col-12 row mt-3`}>
          {sortedProducts.length > 0 &&
            sortedProducts.map((product, index) => (
              <div
                className={`col-xl-${
                  isMenuOpen ? "4" : "3"
                } col-lg-4 col-md-4 col-sm-6 col-6 mb-3 transitionable-column`}
                key={index}
              >
                <div className="card-type-one p-2 mb-4 d-flex align-items-center justify-content-between flex-column h-100">
                  <div className="col-12 d-flex justify-content-end">
                    <FavoriteButton product={product} />
                  </div>

                  <Link
                 to={`/${language}/urun-detay/${encodeURIComponent(
                  removeTurkishChars(
                    language == "en" ?  product.page_url_en.toLowerCase().replace(/ /g, "-")  :
                    product.page_url.toLowerCase().replace(/ /g, "-")
                  )
                )}?attribute=${
                  language == "en" ? product.comb_prices[0]?.comb_atts.length > 0 &&
                  product.comb_prices[0]?.comb_atts[0].attribute_value.value_name_en.replace(
                    / /g,
                    "-"
                  )   : 
                  product.comb_prices[0]?.comb_atts.length > 0 &&
                  product.comb_prices[0]?.comb_atts[0].attribute_value.value_name.replace(
                    / /g,
                    "-"
                  )

                }`}
                  >
                    <div className="col-12 d-flex justify-content-center products-img">
                      {product.product_images.length ? (
                        <img
                          crossOrigin="anonymous"
                          src={
                            product.product_images &&
                            product.product_images.length > 0
                              ? REACT_APP_BASE_URL +
                                product.product_images[0].image_url
                              : noImage
                          }
                          alt={product.product_name}
                          onLoad={(e) => e.target.classList.add("loaded")}
                        />
                      ) : (
                        <img alt="perfume atelier" src={noImage}></img>
                      )}
                    </div>
                    <h2 className="title-main">
                    <b>{language == 'en' ? product.product_name_en : product.product_name}</b>
                    </h2>
                    <p style={{ color: "#bdbdbd" }}>
                      <span>
                      {language == 'en' ? product.comb_prices[0]?.comb_atts.length > 0 &&
                        product.comb_prices[0]?.comb_atts[0].attribute_value
                          .value_name_en : product.comb_prices[0]?.comb_atts.length > 0 &&
                        product.comb_prices[0]?.comb_atts[0].attribute_value
                          .value_name}
                      </span>{" "}
                    </p>

                    <h6>
                      {product.comb_prices[0]?.price ? (
                        <b>{product.comb_prices[0].price}₺</b>
                      ) : (
                        "bulunamadı"
                      )}
                    </h6>
                  </Link>
                  <AddBasketButton product={product} />
                </div>
              </div>
            ))}
        </div>
      </InfiniteScroll>
    );
    setProductsView(productsView);
  }, [products, sortOption, isMenuOpen]);

  return (
    <>
      <Helmet>
        <title>{title} | Perfume Atelier</title>
        <link rel="canonical" href={`https://www.perfumeatelier.com.tr/${language}${canonical}`} />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <div className="products-main container-fluid main-space">
        <div className="row">
          <div className="col-12  d-block">
            <div className="mobile-filter-menu">
              <nav className=" navbar navbar-expand-lg  justify-content-start">
                <button
                  className="button-type-one me-lg-0 me-md-2 me-0"
                  type="button"
                  onClick={toggleMenu}
                >
                  {isMenuOpen ?  data.filtreleri_gizle : data.filtreleri_goster}
                </button>
              </nav>
              {isMobile && (
                <Menu
                  isOpen={isMenuOpen}
                  onStateChange={({ isOpen }) => setIsMenuOpen(isOpen)}
                  left
                  className="bg-white "
                >
                  <div className="collapse navbar-collapse ">
                    <ul className="navbar-nav  d-flex mb-ul p-3 mt-4">
                      {smellsData.map((smell, index) => (
                        <li
                          style={{ cursor: "pointer" }}
                          key={index}
                          className={`header-dropdown-item d-flex flex-column align-items-start  ${
                            activeCategory === smell.title ? "active" : ""
                          }`}
                          onClick={() => handleCategoryClick(smell.title)}
                        >
                          {smell.title}
                        </li>
                      ))}
                    </ul>
                  </div>
                </Menu>
              )}
            </div>
          </div>
          <div className=" col-12 ">
            <div className="col-lg-12 col-md-12 col-12 d-lg-flex d-md-flex justify-content-between align-items-center">
              <div
                className={`col-${
                  isMenuOpen ? "9" : "12"
                } d-lg-flex d-md-flex justify-content-between flex-grow-1`}
              >
                {" "}
                <h1 className="page-title">
                  {" "}
                  <b>{pageTitle} </b>
                </h1>
                <div className="position-relative mobil-filter-size">
                  <button className="button-type-four " onClick={toggleFilter}>
                  {data.onerilen_siralama} <IoIosArrowDown />
                  </button>
                  {showFilter && (
                    <div
                      className={`card-type-two filter-section  mt-2 w-100 ${
                        showFilter ? "fade-top" : ""
                      }`}
                    >
                      <p
                        onClick={(e) => toggleSortOrder(e, "En Düşük Fiyat")}
                        className="sorted-select-hover"
                      >
 {data.en_dusuk_fiyat}                      </p>
                      <p
                        onClick={(e) => toggleSortOrder(e, "En Yüksek Fiyat")}
                        className="sorted-select-hover"
                      >
 {data.en_yuksek_fiyat}                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-start ">
              {isMenuOpen && !isMobile && (
                <div className="col-3">
                  {smellsData.map((smell, index) => (
                    <li
                      style={{ cursor: "pointer" }}
                      key={index}
                      className={`header-dropdown-item d-flex flex-column align-items-start  ${
                        activeCategory === smell.title ? "active" : ""
                      }`}
                      onClick={() => handleCategoryClick(smell.title)}
                    >
                      {smell.title}
                    </li>
                  ))}
                </div>
              )}
              <div className="">
                <p className="mb-lg-0 mb-3 mt-lg-1 mt-3">{smellDesc} </p>

                {categoryView.length > 0 ? (
                  <div className={` col-12  row mt-3`}>
                    {categoryView.length > 0 &&
                      categoryView.map((product, index) => (
                        <div
                          className={`col-xl-${
                            isMenuOpen ? "4" : "3"
                          } col-lg-4 col-md-4 col-sm-6 col-6 mb-3 transitionable-column`}
                          key={index}
                        >
                          <div className="card-type-one p-2 mb-4 d-flex align-items-center justify-content-between flex-column h-100">
                            <div className="col-12 d-flex justify-content-end">
                              <FavoriteButton product={product} />
                            </div>

                            <Link
                           to={`/${language}/urun-detay/${encodeURIComponent(
                            removeTurkishChars(
                              language == "en" ?  product.page_url_en.toLowerCase().replace(/ /g, "-")  :
                              product.page_url.toLowerCase().replace(/ /g, "-")
                            )
                          )}?attribute=${
                            language == "en" ? product.comb_prices[0]?.comb_atts.length > 0 &&
                            product.comb_prices[0]?.comb_atts[0].attribute_value.value_name_en.replace(
                              / /g,
                              "-"
                            )   : 
                            product.comb_prices[0]?.comb_atts.length > 0 &&
                            product.comb_prices[0]?.comb_atts[0].attribute_value.value_name.replace(
                              / /g,
                              "-"
                            )
        
                          }`}
                            >
                              <div className="col-12 d-flex justify-content-center products-img">
                                {product.product_images[0] ? (
                                  <img
                                    crossOrigin="anonymous"
                                    src={
                                      product.product_images &&
                                      product.product_images.length > 0
                                        ? REACT_APP_BASE_URL +
                                          product.product_images[0].image_url
                                        : noImage
                                    }
                                    alt={
                                      product.product_images &&
                                      product.product_images.length > 0
                                        ? product.product_name
                                        : "perfume atelier"
                                    }
                                    onLoad={(e) =>
                                      e.target.classList.add("loaded")
                                    }
                                  />
                                ) : (
                                  <img
                                    alt="perfume atelier"
                                    src={noImage}
                                  ></img>
                                )}
                              </div>
                              <h2 className="title-main">
                              <b>{language == 'en' ? product.product_name_en : product.product_name}</b>
                              </h2>
                              <p style={{ color: "#bdbdbd" }}>
                                <span>
                                 
                                {language == 'en' ? product.comb_prices[0]?.comb_atts.length > 0 &&
                        product.comb_prices[0]?.comb_atts[0].attribute_value
                          .value_name_en : product.comb_prices[0]?.comb_atts.length > 0 &&
                        product.comb_prices[0]?.comb_atts[0].attribute_value
                          .value_name}
                                </span>{" "}
                              </p>
                              <h6>
                                {product.comb_prices[0]?.price ? (
                                  <b>{product.comb_prices[0].price}₺</b>
                                ) : (
                                  "bulunamadı"
                                )}
                              </h6>
                            </Link>
                            <AddBasketButton product={product} />
                          </div>
                        </div>
                      ))}
                  </div>
                ) : (
                  productsView
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SmellsComponent;
