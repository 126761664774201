import { FETCH_BEST_SUCCESS } from "../actions/bestSellerActions";

const initState = {
    data: [], // Kategorileri içerecek olan veri yapısı
};

const newBestReducer = (state = initState, action) => {
    switch (action.type) {
        case FETCH_BEST_SUCCESS:
            return {
                ...state,
                data: action.payload, // Veriyi "data" alanına ekleyelim
            };
        default:
            return state;
    }
};


export default newBestReducer;

