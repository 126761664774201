import React from "react";
import { Helmet } from "react-helmet";
import blogsData from "../data/blog.json";
import ReactHtmlParser from "react-html-parser";
import { removeTurkishChars } from "../utils/urlUtils";
import { useSelector } from "react-redux";

const BlogComponent = () => {
  const { data } = useSelector((state) => state.language);
  const language = useSelector((state) => state.language.language);
  const metaDescriptionTag = document.querySelector(
    'meta[name="description"]'
  );
  if (metaDescriptionTag) {
    metaDescriptionTag.setAttribute("content", "En güncel makalelerimizi ve haberlerimizi keşfedin. İlginç konular, faydalı ipuçları ve uzman görüşleri ile dolu blog sayfamızda sizleri bekliyor.");
  } else {
    const newMetaTag = document.createElement("meta");
    newMetaTag.name = "description";
    newMetaTag.content = "En güncel makalelerimizi ve haberlerimizi keşfedin. İlginç konular, faydalı ipuçları ve uzman görüşleri ile dolu blog sayfamızda sizleri bekliyor.";
    document.head.appendChild(newMetaTag);
  }
  return (
    <>
      <Helmet>
        <title>Blog | Perfume Atelier</title>
        <meta
          name="description"
          content="Perfume Atelier ile Kokuyu Hissedin!"
        />
        <link rel="canonical" href={`https://www.perfumeatelier.com.tr/${language}/blog`} />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <div className="container-fluid main-space">
        <div className="col-12">
          <div>
            <h1 className="mb-3">
              <b>Perfume Atelier</b>
            </h1>
            <h2>Blog</h2>
          </div>
          <div className="d-lg-flex d-md-flex justify-content-center p-0 flex-wrap mb-3 mt-3">
            {blogsData.map((blog, index) => (
              <div
                key={blog.no}
                className="col-lg-6 col-md-6 col-12 pe-lg-3 pe-md-3"
              >
                <div className="card-type-two text-start d-lg-flex mb-3 p-lg-0 p-3 h-100">
                  <div className="col-lg-5 d-flex align-items-center justify-content-center blog-main-img">
                    <img
                      src={blog.img}
                      className="p-lg-2 p-4 main-radius"
                      alt={language == 'en' ? blog.title_en : blog.title}
                    />
                  </div>
                  <div className="col-lg-7 d-flex justify-content-center flex-column p-lg-2">
                    <h4>{language == 'en' ? blog.title_en : blog.title} </h4>
                    <p>{ReactHtmlParser(language == 'en' ? (blog.info_en).slice(0,280) : (blog.info).slice(0,280))}</p>
                    <a
                      href={`/${language}/blog/${removeTurkishChars(blog.title)
                        .replace(/\s+/g, "-")
                        .toLowerCase()}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <button
                        id="more_btn"
                        className="button-type-two"
                        style={{ width: "max-content" }}
                      >
                        {data.daha_fazla}
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogComponent;
