import React from "react";
import { useSelector } from "react-redux";

import img1 from "../assets/img/perfume_03.webp";

const NotFoundComponent = () => {
  const language = useSelector(state => state.language.language);

  return (
    <div className="container-fluid main-space">
      <div className="row">
        <div className="col-lg-6 col-md-12 d-flex flex-column justify-content-center align-items-center">
          <div className="d-flex flex-column align-items-start">
          <h1>404 NOT FOUND</h1>
          <br />
          <h2>Aradığınız sayfa bulunamıyor</h2>
          <br />
          <a href={`/${language}/`}> <button className="button-type-one">Anasayfaya Git</button></a>
          
        </div>
        </div>
        <div className="col-lg-6 col-md-12 d-flex justify-content-center align-items-center mt-4 mt-lg-0">
          <img
            src={img1}
            style={{ maxWidth: "100%", maxHeight: "100%", borderRadius: "50%", width: "800px", height: "500px", objectFit: "cover" }}
            alt="404 Image"
          />
        </div>
      </div>
    </div>
  );
};

export default NotFoundComponent;
